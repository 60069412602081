import React from 'react'
import { AboutView } from '@views'
import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'About'} />
}

const AboutPage = () => {
  return <AboutView />
}

export default AboutPage
